<template>
  <div class="container">
    <h1 class="mb-4">Ügyfelek</h1>
    <div class="row">
      <div class="col-12">
        <div class="white-box">
          <div class="listitem row" v-for="item in clients" :key="item.id">
            <div class="col-auto">
              <button class="img">
                <img :src="item.image" v-if="item.image" :alt="item.name" />
                <BaseIcon v-else name="office" />
              </button>
            </div>
            <div class="col-4">
              <button class="title">{{item.name}}</button>
            </div>
            <div class="col-3">
              <div class="title">Projekt neve</div>
              <div class="date">lorem ispum</div>
            </div>
            <div class="col-auto text-end">
              <span
                class="badge"
                v-for="tag in item.tags"
                :key="tag.id"
                :class="tag.colorName"
              >
                {{tag.name}}
              </span>
            </div>
            <div class="col money" :class="{ income: item.balance > 0 }">
              {{
                Number.parseInt(item.balance).toLocaleString("hu-HU", {
                  maximumFractionDigits: 0,
                })
              }}
              <span class="currency">Ft</span>
              <div class="sub" v-if="item.future != 0">
                <BaseIcon name="rest" />
                <span>
                  {{
                    Number.parseInt(item.future).toLocaleString("hu-HU", {
                      maximumFractionDigits: 0,
                    })
                  }}
                  <span class="currency">Ft</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import BaseIcon from "../../components/BaseIcon";
export default {
  name: "Clients",
  components: { BaseIcon },
  data() {
    return {
      clients: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      fetch(process.env.VUE_APP_API + "/clients")
        .then((res) => res.json())
        .then((response) => {
          if (response.loginNeed) {
            this.$router.push("/login");
          }
          this.clients = response;
        })
        .catch((error) => {
          this.message = error;
        });
    },
  },
};
</script>
