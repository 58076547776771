<template>
  <span
    v-html="require(`../assets/icons/${name}.svg`)"
    :style="color !== undefined ? `color:${color}` : ''"
    :class="classData"
  />
</template>

<script>
export default {
  name: "BaseIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    color: String,
    class: String,
  },
  data() {
    return {
      classData: this.class !== undefined ? "icon " + this.class : "icon",
    };
  },
};
</script>
